/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
    font-family: Poppins-Regular;
    src: url('../fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.ttf');
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

/*---------------------------------------------*/
a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #f38b2b;
    color: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
    color: -o-linear-gradient(to left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

/*---------------------------------------------*/
input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #adadad;
}
input:-moz-placeholder {
    color: #adadad;
}
input::-moz-placeholder {
    color: #adadad;
}
input:-ms-input-placeholder {
    color: #adadad;
}

textarea::-webkit-input-placeholder {
    color: #adadad;
}
textarea:-moz-placeholder {
    color: #adadad;
}
textarea::-moz-placeholder {
    color: #adadad;
}
textarea:-ms-input-placeholder {
    color: #adadad;
}

/*---------------------------------------------*/
button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #666666;
    line-height: 1.5;
    margin-right: 5px;
}

.txt2 {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
}
.txt3 {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #666666;
    line-height: 1.5;
    display: block;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/
.sidebar-header {
    background: #732208;
}
.sidebar {
    background: #3e1305;
}
.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2;
}
.wrap-input-100 {
}
.wrap-input-100 .wrap-input101 {
    float: left;
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 25%;
    height: 45px;
    background: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #adadad;
    margin-top: 2px;
    margin-right: 5px;
}
.wrap-input-100 .wrap-input102 {
    width: 73%;
    float: left;
}

.wrap-login100 {
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 40px 55px 33px 55px;

    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
    width: 100%;
}

.login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 30px;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 15px;
}

.input100 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;

    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
}
.wrap-input1002 .checkbox label,
.wrap-input1002 .checkbox a {
    font-size: 13px;
}
/*---------------------------------------------*/
.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.focus-input100::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;

    background: #f38b2b;
    background: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
    background: -o-linear-gradient(to left, #21d4fd, #b721ff);
    background: -moz-linear-gradient(to left, #21d4fd, #b721ff);
    background: linear-gradient(to left, #21d4fd, #b721ff);
}

.focus-input100::after {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #999999;
    line-height: 1.2;

    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;
    padding-left: 5px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
    top: -15px;
}

.input100:focus + .focus-input100::before {
    width: 100%;
}

.has-val.input100 + .focus-input100::after {
    top: -15px;
}

.has-val.input100 + .focus-input100::before {
    width: 100%;
}

.input-error {
    color: rgb(235, 54, 54);
    margin-top: -10px;
    /* margin-bottom: 10px; */
    font-size: 14px;
}
/*---------------------------------------------*/
.btn-show-pass {
    font-size: 15px;
    color: #999999;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.btn-show-pass:hover {
    color: #f38b2b;
    color: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
    color: -o-linear-gradient(to left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
}

.btn-show-pass.active {
    color: #f38b2b;
    color: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
    color: -o-linear-gradient(to left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
    margin-bottom: 10px;
    clear: both;
}

.wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
}

.login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #a64bf4;
    background: -webkit-linear-gradient(
        to right,
        #21d4fd,
        #b721ff,
        #21d4fd,
        #b721ff
    );
    background: -o-linear-gradient(
        to right,
        #21d4fd,
        #b721ff,
        #21d4fd,
        #b721ff
    );
    background: -moz-linear-gradient(
        to right,
        #21d4fd,
        #b721ff,
        #21d4fd,
        #b721ff
    );
    background: linear-gradient(to right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    top: 0;
    left: -100%;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
}

.wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
    background: #d49116;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 40px 15px 33px 15px;
    }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;

    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f06a';
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;

    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}
.text-center.p-t-115 {
    text-align: center;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

/* To format the placeholder text color */
::-webkit-input-placeholder {
    color: #ecf0f1;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ecf0f1;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ecf0f1;
}

:-ms-input-placeholder {
    color: #ecf0f1;
}

/*dashboar*/
.side-menu {
    height: 100vh !important;
    overflow-y: scroll;
}
.dash-wapp {
    margin-top: -30px;
    padding-bottom: 60px;
}
.heading-tit {
    font-size: 24px;
    padding: 15px;
    margin-bottom: 15px;
}
.profile-header {
    background: #fff;
    padding: 15px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
.rounded-circle-avt {
    border-radius: 50%;
    margin-left: 5px;
    width: 20px;
    height: 20px;
}
.dropdown-item .rounded-circle-avts-ct {
    width: 40px;
    height: 40px;
}
.dropdown-item span {
    display: block;
}
.profile-header .rounded-circle {
    border-radius: 50%;
    margin-left: 5px;
    width: 150px;
    height: 150px;
}
img {
    max-width: 100%;
    height: auto;
}
.profile-header .custom-file {
    margin-top: 50px;
}

.profile-edit-war {
}
.profile-edit-war .padding {
}
.profile-edit-war .tab-content {
    padding: 30px;
    background: #fff;
}
.profile-edit-war .nav-tabs .nav-link {
    cursor: pointer;
}
.profile-edit-war .tab-content > .tab-pane h5 {
    margin-bottom: 20px;
    font-size: 18px;
    background: #6c757d;
    color: #fff;
    padding: 7px;
    text-transform: uppercase;
}
.profile-edit-war .tab-content > .tab-pane h6 {
    color: #56081a;
    font-weight: 600;
    margin-bottom: 15px;
}
.profile-edit-war .tab-content > .tab-pane .checkbox {
    margin-bottom: 10px;
}
.profile-edit-war .tab-content > .tab-pane .checkbox label {
    font-size: 15px;
    color: #757575;
}
.profile-edit-war .tab-content > .tab-pane .checkbox input {
    margin-right: 8px;
}
.submit-btn {
    text-align: center;
    padding: 15px 0px;
}
.mb-2 {
    padding: 10px 30px;
    text-transform: uppercase;
}

.custome-chat {
    background-color: #fff;
}
.custome-chat tr th {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}
.custome-chat tbody tr td {
    font-size: 13px;
}

.drop {
}
.drop .btn {
    border: none;
    background: none;
    padding: 0px;
    font-size: 13px;
}
.drop .dropdown-item {
    font-size: 13px;
}
.drop .btn:not(:disabled):not(.disabled).active,
.drop .btn:not(:disabled):not(.disabled):active,
.show > .drop .btn.dropdown-toggle {
    color: #fff;
    border: none !important;
    background: none !important;
}
.drop .btn-secondary.focus,
.drop .btn-secondary:focus {
    color: #fff;
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.drop .rounded-circle-avts {
    margin-left: -5px;
}
.p-3 {
    padding: 0px 10px !important;
}
.card-cust {
    -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
}
.border-left-warning {
    border-left: 0.25rem solid #f6c23e !important;
    border-top: none;
    border-bottom: none;
    border-right: none;
}
.card-cust .card-title {
    font-size: 14px;
    color: #ff9900;
    font-weight: 500;
    text-transform: uppercase;
}
.card-cust .card-text {
    color: #5a5c69 !important;
    font-weight: 700 !important;
    font-size: 1.25rem;
}

.card-cust .icons .fa {
    color: #dddfeb !important;
    font-size: 2em;
}

/*chat box*/
#frame {
    width: 100%;
    height: 70vh;
    min-height: 250px;
    max-height: 600px;
    background: #ffffff;
    border-radius: 5px;
    -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
    #frame {
        width: 100%;
        height: 60vh;
    }
    #frame .content .message-input .wrap img {
        height: 50px !important;
    }
}

#frame .content {
    float: left;
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    position: relative;
}
@media screen and (max-width: 735px) {
    #frame .content {
        width: calc(100% - 58px);
        min-width: 300px !important;
    }
}
@media screen and (min-width: 900px) {
    #frame .content {
        width: calc(100% - 340px);
    }
}
#frame .content .contact-profile {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #f5f5f5;
}
#frame .content .contact-profile img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin: 9px 12px 0 9px;
}
#frame .content .contact-profile p {
    float: left;
    margin-top: 15px;
}
#frame .content .contact-profile .social-media {
    float: right;
}
#frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
    color: #435f7a;
}
#frame .content .messages {
    height: auto;
    min-height: calc(100% - 93px);
    max-height: calc(100% - 93px);
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 735px) {
    #frame .content .messages {
        max-height: calc(100% - 105px);
    }
}
#frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
}
#frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
}
#frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
    background: #17a2b8;
    color: #f5f5f5;
}
#frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
    background: #f5f5f5;
    float: right;
}
#frame .content .messages ul li img {
    width: 22px;
    border-radius: 50%;
    float: left;
}
#frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;
}
@media screen and (min-width: 735px) {
    #frame .content .messages ul li p {
        max-width: 300px;
    }
}
#frame .content .message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
}
#frame .content .message-input .wrap {
    position: relative;
}
#frame .content .message-input .wrap input {
    font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
    float: left;
    border: none;
    width: calc(100% - 90px);
    padding: 11px 32px 10px 8px;
    font-size: 14px;
    color: #32465a;
    background: #f7f6f6;
}
@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap input {
        padding: 15px 32px 16px 8px;
        font-size: 16px !important;
        height: 50px;
    }
}
#frame .content .message-input .wrap input:focus {
    outline: none;
}
#frame .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #435f7a;
    opacity: 0.5;
    cursor: pointer;
}
@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap .attachment {
        margin-top: 17px;
        right: 65px;
    }
}
#frame .content .message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
    margin-bottom: 10px;
}
#frame .content .message-input .wrap .attachment:hover {
    opacity: 1;
}
#frame .content .message-input .wrap button {
    border: none;
    height: 40px;
    padding: 8px;
    padding-right: 5px;
    cursor: pointer;
    background: #17a2b8;
    color: #f5f5f5;
}
#frame .content .message-input .wrap img {
    background: #17a2b8;
    padding: 15px;
    height: 43px;
    cursor: pointer;
}
@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap button {
        padding: 16px 0;
    }
}
#frame .content .message-input .wrap button:hover {
    background: #435f7a;
}
#frame .content .message-input .wrap button:focus {
    outline: none;
}

.row-bg-1 {
    background: #fff;
    padding: 20px 0px;
}
.row-bg-2 {
    background: #f9f9f9;
    padding: 20px 0px;
}
.row-bg-3 {
    background: #fff;
    padding: 20px 0px;
}
.row-bg-4 {
    background: #f9f9f9;
    padding: 20px 0px;
}
.row-bg-5 {
    background: #fff;
    padding: 20px 0px;
}

.chat-info {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.earningTable{
    width: 100% !important;
}
.th{
    width: 40% !important;
    /* border: 1px solid black !important; */
}