.button {
    cursor: pointer;
}

.button:hover {
    color: #FF9900;
}

/* Astro**************************************************** */

.astro {
    height: 84vh;
    overflow-y: scroll;

}

.astroView {
    height: 86vh;
    overflow-y: scroll;
    width: 100%;
}

.astroView1 {
    position: sticky;
    top: -1px;
    border: 1px solid gray;
    background-color: white;
}

.astroView::-webkit-scrollbar {
    width: 5px;
    height: 0.1px;
}

.astroView::-webkit-scrollbar-thumb {
    background-color: #ffc107;
    border-radius: 50px;
}

.onOff {
    height: 78vh;
    overflow-y: scroll;
    width: 100%;
}

.onOff1 {
    position: sticky;
    top: -1px;
    border: 1px solid gray;
    background-color: white;
}

.onOff::-webkit-scrollbar {
    width: 5px;
    height: 0.1px;
}

.onOff::-webkit-scrollbar-thumb {
    background-color: #ffc107;
    border-radius: 50px;
}

/* Stats ************************************************************************ */

.stats {
    height: 630px;
    overflow: scroll;
    padding-bottom: 5px;
}

.stats::-webkit-scrollbar {
    width: 5px;
    height: 0.1px;
}

.stats::-webkit-scrollbar-thumb {
    background-color: #ffc107;
    border-radius: 50px;
}



.user {
    height: 82vh;
    overflow-y: scroll;
    width: 100%;
}

.user::-webkit-scrollbar {
    width: 10px;
    height: 0.1px;
}
.user::-webkit-scrollbar-thumb {
    background-color: #ffc107;
    border-radius: 90px;
    border: 1px solid black;
}



.callMissed {
    height: 74vh;
    overflow-y: scroll;
    width: 100%;
}

.callMissed::-webkit-scrollbar {
    width: 5px;
    height: 0.1px;
}

.callMissed::-webkit-scrollbar-thumb {
    background-color: #ffc107;
    border-radius: 50px;
}



.Loading {
    padding: 10px;
    margin-top: 5px;
    color: #FFA214;
    font-weight: 600;
    font-size: 18px;
}

.clockLoader {
    --clock-color: #FFA214;
    --clock-width: 4rem;
    --clock-radius: calc(4rem / 2);
    --clock-minute-length: calc(4rem * 0.4);
    --clock-hour-length: calc(4rem * 0.2);
    --clock-thickness: 0.2rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border: 3px solid orange;
    border-radius: 50%;
}

.clockLoader::before,
.clockLoader::after {
    position: absolute;
    content: "";
    top: calc(calc(4rem / 2) * 0.25);
    width: 0.2rem;
    background: orange;
    border-radius: 10px;
    transform-origin: center calc(100% - calc(0.2rem / 2));
    -webkit-animation: spin infinite linear;
    animation: spin infinite linear;
}

.clockLoader::before {
    height: calc(4rem * 0.4);
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.clockLoader::after {
    top: calc(calc(4rem / 2) * 0.25 + calc(4rem * 0.2));
    height: calc(4rem * 0.2);
    -webkit-animation-duration: 15s;
    animation-duration: 15s;
}

@-webkit-keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}