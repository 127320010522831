.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';

body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 250px;
    max-width: 250px;
    background-color: #f99b0f;
    color: darkslategray;
    margin-left: -250px;
    transition: all 0.5s;
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    /* background: #6d7fcc; */
    background-color: #e18f13;
}

.sidebar-header h3 {
    color: black;
    padding: 1em;
}

.sidebar ul p {
    color: black;
    padding: 10px;
}

.menu-open {
    background: #ff9900;
}

.nav-item:hover {
    color: #ff9900;
    background: #fff;
}

.items-menu {
    color: #fff;
    background: #ff9900;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
    color: black;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #ff9900;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #ff9900;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }

    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span {
        display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

.messages {
    padding: 5% 0;
    overflow: auto;
    flex: auto;
}

.d-flex-screen .btn {
    font-size: 14px;
    width: 70%;
}
@media only screen and (max-width: 500px) {
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
    .d-flex-screen {
        flex-wrap: wrap;
        justify-content: center;
    }

    .d-flex-screen .form-control {
        margin-bottom: 20px;
    }

    .heading-tit {
        font-size: 18px;
        padding: 15px;
        margin-bottom: -5px;
    }
}
